import React from "react"
import { motion } from "framer-motion"
import Seo from "../components/Seo"
import { navigate } from "gatsby"
import { useSplashLandingProjectQuery } from "../hooks/useSplashLandingProject"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useWpSplashQuery } from "../hooks/useWpSlash"
import VideoSplash from "../components/PageLayout/VideoSplash"

export default function Home() {
  const { wpPage } = useWpSplashQuery()
  const { targetPost } = useSplashLandingProjectQuery()
  const backgroundImage = {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
  }

  return (
    <>
      <Seo title="Home" />
      {wpPage.splash !== null && wpPage.splash.typeOfSplash === true ? (
        <VideoSplash videoId={wpPage.splash.video} />
      ) : (
        wpPage.splash.image !== null && (
          <div style={backgroundImage}>
            <GatsbyImage
              image={getImage(wpPage.splash.image.localFile)}
              alt=""
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        )
      )}
      <motion.section
        className="canvas"
        onClick={() => {
          navigate(targetPost.uri)
        }}
      />
    </>
  )
}
