import React from "react"
import Vimeo from "@u-wave/react-vimeo"

const VideoSplash = ({ videoId }) => {
  const videoContainer = {
    // position: "fixed",
    // left: 0,
    // top: 0,
    // width: "100vw",
    // heigth: "100vh",
  }
  return (
    <Vimeo
      style={videoContainer}
      id="vimeo-splash-player"
      video={videoId}
      paused={false}
      background={true}
      // responsive={true}
      loop={true}
      // controls={true}
      // dnt={true}
    />
  )
}

export default VideoSplash
