import { useStaticQuery, graphql } from "gatsby"

export const useSplashLandingProjectQuery = () => {
  const data = useStaticQuery(graphql`
    query splashLandingTargetProject {
      wpPage(slug: { eq: "works" }) {
        id
        works {
          splashLandingProject {
            ... on WpPost {
              id
              uri
              id
              title
            }
          }
        }
      }
    }
  `)

  return {
    targetPost: {
      ...data.wpPage.works.splashLandingProject[0],
      uri: "/works" + data.wpPage.works.splashLandingProject[0].uri,
    },
  }
}
