import { useStaticQuery, graphql } from "gatsby"

export const useWpSplashQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "splash" }) {
        id
        splash {
          typeOfSplash
          video
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  return data
}
